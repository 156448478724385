<template>
  <div class="container p-0 position-relative pb-5" style="background-color: black;">

    <div class="card-header">
      <img src="@/assets/logo.png" style="
                                          height:130px;
                                          position: absolute;
                                          transform: translate(-50%, -50%);
                                          left: 50%;
                                          top: 80px;
                                          border-color: white 5px;
                              
                                          text-align: center;
                                        ">

    </div>
    <h1 class="text-center text-white" style="font-weight: 500; font-size: 30px;">คาเฟ่ &ร้านอาหารใกล้เรา</h1>
    <div class="card-body text-center" style="border-radius: 20px;">


      <div class="cafe-box mt-2" v-for="cafe_data in  cafe " :key="cafe_data.id">

        <div class="position-absolute justify-content-start" style="left: 0; top: 0; bottom: 0; border-radius: 20px;">
          <img :src="cafe_data.picture[0].picture">
          <span class="container position-absolute text-start "
            style="white-space:nowrap;overflow: hidden; padding: 5px;">
            <h4>ร้าน{{ cafe_data.name }}</h4>
            <h6 style="font-weight: 300;">{{ cafe_data.name }}</h6>
            <h5 style="font-weight: 400; "><a :href="'tel:' + cafe_data.tel"> <i
                  style="font-size: 15px; margin-right: 3px; color: var(--color);" class="bi bi-telephone-fill"></i>{{
                    cafe_data.tel }}</a></h5>


            <a :href="cafe_data.link_map" target="_blank"> <object :data="google_map" type="image/svg+xml"
                style="height: 28px;pointer-events: none;"></object></a>
            <a :href="cafe_data.line" target="_blank"><object :data="line" type="image/svg+xml"
                style="height: 28px;pointer-events: none;" v-if="cafe_data.line"></object></a>
            <a :href="cafe_data.facebook" target="_blank"><object :data="facebook" type="image/svg+xml"
                style="height: 28px;pointer-events: none;" v-if="cafe_data.facebook"></object></a>
            <a :href="cafe_data.instragram" target="_blank"><object :data="instragram" type="image/svg+xml"
                style="height: 28px;pointer-events: none;" v-if="cafe_data.instragram"></object></a>


          </span>

        </div>

        <!--         
            {{ cafe_data.name }} -->



      </div>




    </div>


  </div>
</template>

<script>
import google_map from '@/assets/google.svg';
import line from '@/assets/line.svg';
import facebook from '@/assets/facebook.svg';
import instragram from '@/assets/Instagram.svg';

import liff from "@line/liff";
import { defineComponent, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
//import liff from "@line/liff";
export default defineComponent({
  name: "Dashboard",

  setup() {
    const store = inject("store");
    const router = inject("router");

    const cafe = store.getters.getCafe;
    const Profire = store.getters.getProfire;
    const ProfireLine = store.getters.getProfireLine;

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const edit = () => {
      store.dispatch(SET_REGISTER, Profire);
      router.push({ name: "Edit" });
    };

    const go_profile = () => {
      router.push({ name: "Profile" });
    };

    const go_product = () => {
      liff.ready.then(() => {
        liff.openWindow({
          url: `line://app/${process.env.VUE_APP_PRODUCT}`,
          external: false,
        });
      });
    };

    const open_link = (link) => {
      console.log(link);
      window.open(`${link}`, '_blank').focus();
    };


    return {
      ProfireLine,
      Profire,
      edit,
      go_profile,
      go_product,
      cafe,
      google_map,
      line,
      facebook,
      instragram,
      open_link
    };
  },
});

</script>

<style scoped>
.googlemap {
  src: "@/assets/google.svg";
  /* Scoped CSS here */
}

.googlepin {
  position: absolute;
  width: 30px;
  top: 10px;
  left: 6px;
}

.submit-button {
  color: #4E6629 !important;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  font-size: 22px;
  width: 150px;
  border-radius: 25px;
  border: none;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 8px 10px 8px 10px;
}

.submit-button:hover,
.submit-button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.submit-button:hover {
  transform: translateY(-1px);
}

.submit-button:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.card-body {
  overflow-y: scroll;
  height: 600px;
  padding: 16px;
  margin-left: 1em;
  margin-right: 1em;
  border-radius: 20px;
  border: 2cm black;
  background-color: rgb(255, 255, 255) !important;
  align-items: center !important;
  justify-content: center !important;
}

.card {

  background: transparent !important;
  background-position: center;
  background-size: cover, contain;
  background-repeat: no-repeat;
  margin: 0;
  width: auto;
}





.card-header {
  padding: 0;
  border-bottom: none;
  margin-bottom: 1rem;
  background-color: black !important;
  height: 150px;
  min-height: 150px;
}

.card-body {
  margin-top: 0;
  background-color: transparent;
  flex: unset !important;
  padding: 16px;
}

.card-footer {
  margin-top: 0;
  background-color: transparent !important;
  border: none;
}


.cafe-box {
  padding: 0px;
  margin-bottom: 1em;
  position: relative;
  min-height: 120px;
  height: 120px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  border-radius: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  justify-content: center;
}

.cafe-box img {
  width: 150px;
  height: 120px;
  object-fit: cover;
  border-radius: 20px 0px 0px 20px;
}
</style>
