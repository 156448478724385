export const SET_CAFE = "setCafe";

export default {
  state: {
    cafe: [],
  },
  getters: {
    getCafe(state) {
      return state.cafe;
    },
  },
  actions: {
    [SET_CAFE](state, payload) {
      state.commit(SET_CAFE, payload);
    },
  },
  mutations: {
    [SET_CAFE](state, payload) {
      state.cafe = {
        ...state.cafe,
        ...payload,
      };
    },
  },
};
